import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';

function TheContent() {
  return (
    <Container component="main" maxWidth="xxl" sx={{padding:'10px'}}>
      <Outlet />
    </Container>
  );
}

export default React.memo(TheContent);
