import { Components } from '@mui/material';
import { PALETTE } from '../palette';

export const MENU: Components['MuiMenu'] = {
  styleOverrides: {
    paper: {
      borderRadius: 0,
      padding: 0,
      boxShadow: PALETTE.boxShadows.shadowUtility,
      border: `1px solid ${PALETTE.utility.light}`,
      minWidth: '165px',
      '&::-webkit-scrollbar': {
        width: '8px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: PALETTE.text.secondary
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.1)',
        backgroundColor: PALETTE.utility.light
      }
    },
    list: {
      padding: '0'
    }
  }
};

export const MENU_LIST: Components['MuiMenuList'] = {
  styleOverrides: {
    root: {
      padding: 0
    }
  }
};

export const MENU_ITEM: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      fontSize: '14px',
      fontWeight: 400,
      color: PALETTE.text.main,
      paddingTop: '8px',
      paddingBottom: '8px'
    }
  }
};
