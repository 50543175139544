import { Components } from '@mui/material';
import { PALETTE } from '../palette';

export const OUTLINED_INPUT: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      fontSize: '14px',
      color: PALETTE.text.primary,
      paddingBottom: '1px',
      marginBottom: '14px',
      borderRadius: 0,
      height: '40px',
      outline: 0,
      backgroundColor: PALETTE.common.white,
      borderColor: PALETTE.primary.light,
      transition: 'box-shadow ease 0.2s',
      '&.Mui-focused': {
        borderColor: PALETTE.primary.main,
        border: 0,
        outline: 0,
        boxShadow: PALETTE.boxShadows.shadowPrimaryLight
      },
      '&.Mui-error': {
        boxShadow: PALETTE.boxShadows.shadowError
      },
      '.Mui-disabled': {
        color: PALETTE.text.primary,
        '-webkit-text-fill-color': PALETTE.text.primary
      }
    },

    multiline: {
      height: 'unset'
    }
  }
};

export const CHECKBOX: Components['MuiCheckbox'] = {
  defaultProps: {
    disableRipple: true
  },
  styleOverrides: {
    root: {
      color: PALETTE.utility.light,
      margin: '5px 8px 5px 8px',
      padding: 0,
      borderRadius: 0,
      width: '20px',
      height: '20px',
      border: `1px solid ${PALETTE.utility.light}`,
      transition: 'ease all 0.3s',
      '&.Mui-checked': {
        color: PALETTE.common.white,
        backgroundColor: PALETTE.primary.light,
        border: `1px solid ${PALETTE.primary.light}`,
        '.MuiSvgIcon-root': {
          width: '18px'
        }
      },
      '&:hover': {
        border: `1px solid ${PALETTE.primary.main}`,
        boxShadow: PALETTE.boxShadows.shadowPrimaryLight
      }
    }
  }
};
