import { Grid2 as Grid, Typography } from '@mui/material';

const RolesIntroSection = ({ img, title, text }: { img: string; title: string; text: any }) => {
  return (
    <Grid container spacing={4} padding="40px 80px" >
      <Grid size={5} display="flex" alignItems="center" justifyContent="center">
        <img src={img} style={{ maxWidth: '100%'}} alt="Image not Found" />
      </Grid>
      <Grid size={7}>
        <Typography variant="h1" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1">{text}</Typography>
      </Grid>
    </Grid>
  );
};

export default RolesIntroSection;
