export const linkTitles = {
  aboutUs: 'About Us',
  admins: 'Administrators',
  aiLab: 'AI Lab',
  aiTools: 'AI Tools',
  home: 'Home',
  login: 'Login',
  parents: 'Parents',
  students: 'Students',
  teachers: 'Teachers',
  termsConditions: 'Terms and Conditions',
};
export const rolesInEducation = {
  parents: linkTitles.parents,
  schools: linkTitles.admins,
  students: linkTitles.students,
  teachers: linkTitles.teachers,
};
export const routeKeys = {
  aboutUs: '/About-Us',
  aiLab: '/AI-Lab',
  aiTools: '/AI-Tools',
  home: '/',
  login: '/',
  parents: '/Parents',
  admins: '/Administrators',
  students: '/Students',
  teachers: '/Teachers',
  termsConditions: '/Terms-and-Conditions',
};

export const companyLinks = [
  {
    text: linkTitles.aboutUs,
    path: routeKeys.aboutUs,
  },
  {
    text: linkTitles.termsConditions,
    path: routeKeys.termsConditions,
  },
];
export const exploreLinks = [
  {
    text: linkTitles.aboutUs,
    path: routeKeys.aboutUs,
  },
  {
    text: linkTitles.aiLab,
    path: routeKeys.aiLab,
  },
  {
    text: linkTitles.aiTools,
    path: routeKeys.aiTools,
  },
  {
    text: linkTitles.login,
    path: routeKeys.login,
  },
];
export const quickLinks = [
  {
    text: linkTitles.admins,
    path: routeKeys.admins,
  },
  {
    text: linkTitles.teachers,
    path: routeKeys.teachers,
  },
  {
    text: linkTitles.parents,
    path: routeKeys.parents,
  },
  {
    text: linkTitles.students,
    path: routeKeys.students,
  },
];

export const btnSecondaryStyle = {
    bgcolor: 'secondary.main',
    color: 'common.white',
    '&:hover': {
      bgcolor: 'secondary.dark',
      color: 'primary.light',
      boxShadow: 'boxShadows.shadowPrimary',
    },
  };