import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { theme } from './themes';
import Router from './routes';
import { LoadingSpinner } from '../shared/components';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<LoadingSpinner />}>
        <CssBaseline>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </CssBaseline>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
