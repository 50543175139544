import { Box, Grid2 as Grid, Stack, Typography } from '@mui/material';
import { theme } from '../../app/themes/theme';
import { CaInterface } from '../utils/types';
import { ReactNode } from 'react';

const TypogTwo = ({ children }: { children: ReactNode }) => {
  return (
    <Typography variant="h3" gutterBottom>
      {children}
    </Typography>
  );
};
const ChallengesVsAdvantages = ({
  advantages,
  challenges,
  imgSrc,
  story,
}: {
  advantages: CaInterface;
  challenges: CaInterface;
  imgSrc: string;
  story: any;
}) => {
  return (
    <Box bgcolor={theme.palette.common.white} borderRadius="50px">
      <Grid container spacing={2} padding="40px">
        <Grid size={6} alignItems="center" sx={{ justifyContent: 'space-between' }}>
          <Stack padding="20px 40px" sx={{ flex: 1, alignItems: 'center' }}>
            <Typography variant="h2" gutterBottom>
              {challenges.title}
            </Typography>
            <TypogTwo>{challenges.one}</TypogTwo>
            <TypogTwo>{challenges.two}</TypogTwo>
            <TypogTwo>{challenges.three}</TypogTwo>
          </Stack>
        </Grid>
        <Grid size={6} alignItems="center">
          <Stack padding="20px 40px" sx={{ flex: 1, alignItems: 'center' }}>
            <Typography variant="h2" gutterBottom>
              {advantages.title}
            </Typography>
            <TypogTwo>{advantages.one}</TypogTwo>
            <TypogTwo>{advantages.two}</TypogTwo>
            <TypogTwo>{advantages.three}</TypogTwo>
          </Stack>
        </Grid>
        <Grid size={3} padding="30px" alignItems="center">
          <img src={imgSrc} style={{ maxWidth: '100%' }} alt="Image not found" />
        </Grid>
        <Grid size={9} padding="30px" display="flex" justifyContent="center" alignItems="center">
          <Typography variant="subtitle2" textAlign="center" justifyContent="center" alignItems="center">
            {story}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChallengesVsAdvantages;
