import { TheContent, TheFooter, TheHeader } from '../';

const TheLayout = () => {
  return (
    <>
      <TheHeader />
      <TheContent />
      <TheFooter />
    </>
  );
};

export default TheLayout;
