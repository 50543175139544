import { Components } from '@mui/material';
import { PALETTE } from '../palette';
import { PRIMARY_FONT_BOLD, PRIMARY_FONT_REG } from '../typography';

export const LIST_ITEM_BUTTON: Components['MuiListItemButton'] = {
  styleOverrides: {
    root: {
      fontFamily: PRIMARY_FONT_REG,
      fontWeight: 400,
      fontSize: '50px',
      textAlign: 'center',
      width: '200px',
      height: '40px',
      padding: '4px 0 0 4px',
      transition: 'all ease-in 0.2s',
      borderRadius:'20px',
      backgroundColor: PALETTE.utility.light,
      '&:hover': {
        boxShadow: PALETTE.boxShadows.shadowPrimary,
        backgroundColor: PALETTE.common.white
      },
      '&:disabled': {
        color: PALETTE.text?.secondary,
        backgroundColor: PALETTE.utility.light
      },
      '&selected': {
        fontFamily: PRIMARY_FONT_BOLD,
        color: PALETTE.primary.main,
        fontWeight: 600,
        backgroundColor: PALETTE.common.white
      }
    }
  }
};
export const LIST_ITEM_TEXT: Components['MuiListItemText'] = {
  styleOverrides: {
    root: {
      fontFamily: PRIMARY_FONT_REG,
      fontWeight: 400,
      fontSize: '50px',
      textAlign: 'center',
    }
  }
};
export const LIST_ITEM_ICON: Components['MuiListItemIcon'] = {
  styleOverrides: {
    root: {
      fontFamily: PRIMARY_FONT_REG,
      fontWeight: 400,
      fontSize: '50px',
      textAlign: 'center',
      
    }
  }
};
