import { useNavigate } from 'react-router-dom';
import { Grid2 as MuiGrid, styled, Typography } from '@mui/material';
import { GetInTouch, HulaTechLogo } from '../../shared/components';
import { theme } from '../../app/themes/theme';
import { companyLinks, exploreLinks, quickLinks, routeKeys } from '../../shared/utils/constants';

const FooterContainer = styled('div')(() => ({
  backgroundColor: theme.palette.primary.main,
  bottom: '0',
  width: '100%',
  height: '200px',
}));

const QuickLinkList = styled('ul')(() => ({
  width: '100%',
  height: 'auto',
  //   TO-DO: Fix indentation of ul/li
}));
const QuickLinkListItems = styled('li')(() => ({
  width: '100%',
  height: 'auto',
  cursor: 'pointer',
}));

const TheFooter = () => {
  const navigate = useNavigate();

  return (
    <>
    <GetInTouch/>
      <FooterContainer>
        <MuiGrid container spacing={4}>
          <MuiGrid size={3} padding='20px 0'>
            <HulaTechLogo variant='white' height="160px" />
          </MuiGrid>
          <MuiGrid size={3}>
            <QuickLinkList>
              <Typography variant="h3">Explore</Typography>
              {exploreLinks.map((item, index) => (
                <QuickLinkListItems key={index} onClick={() => navigate(item.path)}>
                  {item.text}
                </QuickLinkListItems>
              ))}
            </QuickLinkList>
          </MuiGrid>
          <MuiGrid size={3}>
            <QuickLinkList>
              <Typography variant="h3">Quick Links</Typography>
              {quickLinks.map((item, index) => (
                <QuickLinkListItems key={index} onClick={() => navigate(item.path)}>
                  {item.text}
                </QuickLinkListItems>
              ))}
            </QuickLinkList>
          </MuiGrid>
          <MuiGrid size={3}>
            <QuickLinkList>
              <Typography variant="h3">Company</Typography>
              {companyLinks.map((item, index) => (
                <QuickLinkListItems key={index} onClick={() => navigate(item.path)}>
                  {item.text}
                </QuickLinkListItems>
              ))}
            </QuickLinkList>
          </MuiGrid>
        </MuiGrid>
      </FooterContainer>
    </>
  );
};

export default TheFooter;
