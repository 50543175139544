import { Box, CircularProgress } from '@mui/material';

function LoadingSpinner({ color = 'primary' }: { color?: string }) {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 11,
      }}
    >
      <CircularProgress
        sx={{ color }}
      />
    </Box>
  );
}

export default LoadingSpinner;
