import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid2 as MuiGrid, Menu, MenuItem } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { HulaTechLogo } from '../../shared/components';
import { theme } from '../../app/themes/theme';
import { routeKeys } from '../../shared/utils/constants';

const TheHeader = () => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const menuOptions = [
    { title: 'Teacher', path: routeKeys.teachers },
    { title: 'Schools', path: routeKeys.admins },
    { title: 'Parents', path: routeKeys.parents },
    { title: 'Students', path: routeKeys.students },
  ];
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };
  const handleMenuItemClick = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <MuiGrid container spacing={2} bgcolor={theme.palette.common.white} padding="20px">
        <MuiGrid size={{ md: 3 }}>
          <HulaTechLogo height="150px" />
        </MuiGrid>
        <MuiGrid size={{ md: 9 }} display="flex" alignItems="center" justifyContent="space-around">
          <MuiGrid container spacing={2} justifyContent="space-between" alignItems="center">
            <MuiGrid size={{ xs: 0.7, md: 1.5, lg: 1.2 }}>
              <Button
                variant="text"
                size="large"
                color="secondary"
                onClick={() => {
                  navigate(routeKeys.home);
                }}
              >
                Home
              </Button>
            </MuiGrid>
            <MuiGrid size={{ xs: 0.7, md: 2, lg: 2.2 }}>
              <Button
                variant="text"
                size="large"
                color="secondary"
                onClick={handleOpenMenu}
                endIcon={<KeyboardArrowDown />}
              >
                Roles in education
              </Button>
              <Menu
                open={openMenu}
                onClose={() => {
                  handleCloseMenu();
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
              >
                {menuOptions.map((item) => (
                  <MenuItem
                    key={item.path}
                    onClick={() => {
                      handleCloseMenu();
                      handleMenuItemClick(item.path);
                    }}
                  >
                    {item.title}
                  </MenuItem>
                ))}
              </Menu>
            </MuiGrid>
            <MuiGrid size={{ xs: 0.7, md: 1, lg: 1.2 }}>
              <Button variant="text" size="large" color="secondary" onClick={() => navigate(routeKeys.aiTools)}>
                AI Tools
              </Button>
            </MuiGrid>
            <MuiGrid size={{ xs: 1, md: 1.2, lg: 1.5 }}>
              <Button variant="text" size="large" color="secondary" onClick={() => navigate(routeKeys.aboutUs)}>
                About us
              </Button>
            </MuiGrid>
            <MuiGrid size={{ xs: 1, md: 1.2, lg: 1.2 }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  window.open('https://www.ailab.hulatechnology.com', '_blank');
                }}
              >
                AI Lab
              </Button>
            </MuiGrid>
            <MuiGrid size={{ xs: 1, md: 1.2, lg: 1.2 }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  window.open('https://hula-school-portal-prod.firebaseapp.com/auth/login', '_blank');
                }}
              >
                Login
              </Button>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </>
  );
};

export default TheHeader;
