import { TypographyVariantsOptions } from '@mui/material';
import { pxToRem } from '../../shared/utils/helpers';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

export const PRIMARY_FONT_REG = 'Arial, Helvetica, sans-serif';
export const PRIMARY_FONT_BOLD = 'Arial, Helvetica, sans-serif';

export const TYPOGRAPHY: TypographyVariantsOptions = {
  fontFamily: PRIMARY_FONT_REG,
  h1: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(60),
    lineHeight: 1.5
  },
  h2: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(40),
    lineHeight: 1.3
  },
  h3: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(28),
    lineHeight: 1.5
  },
  h4: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(16),
    lineHeight: 1.5
  },
  h5: {
    fontWeight: 700,
    fontSize: pxToRem(13),
    lineHeight: 1.5
  },
  //Used for logo
  h6: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(24),
    lineHeight: 0
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: pxToRem(28),
    lineHeight: 1.5
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: pxToRem(24),
    lineHeight: 1.4
  },
  body1: {
    fontWeight: 400,
    fontSize: pxToRem(20),
    lineHeight: 1.5
  },
  body2: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(14),
    lineHeight: 1.4
  },
  body3: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(9),
    lineHeight: 1.4
  },
  button: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(14),
    lineHeight: 1.75
  },
  caption: {
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: 1.2
  },
  overline: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(11),
    lineHeight: 1,
    textTransform: 'uppercase'
  }
};
