import { createTheme, Theme } from '@mui/material';
import { PALETTE } from './palette';
import { TYPOGRAPHY } from './typography';
import { COMPONENTS } from './components';
import { BREAKPOINTS } from './breakpoints';

export const theme: Theme = createTheme({
  palette: PALETTE,
  typography: TYPOGRAPHY,
  components: COMPONENTS,
  breakpoints: BREAKPOINTS
});
