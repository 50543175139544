import { Box } from '@mui/material';

import Logo from '../../assets/media/HulaTechLogo.svg';
import LogoWhite from '../../assets/media/HulaTechLogoWhite.svg';

interface HulaTechLogoProps {
  height?: string;
  width?: string;
  variant?: string;
}
const HulaTechLogo: React.FC<HulaTechLogoProps> = ({ width = '300px', height = 'auto', variant }) => {
  return (
    <Box>
      <img src={variant === 'white' ? LogoWhite : Logo} alt="Hula Technology" height={height} width={width} />
    </Box>
  );
};

export default HulaTechLogo;
