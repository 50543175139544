import { Components } from '@mui/material';
import { PALETTE } from '../palette';

export const ACCORDION: Components['MuiAccordion'] = {
  styleOverrides: {
    root: {
      marginBottom: 0,
      '&:before': {
        display: 'none'
      },
      '.MuiAccordionSummary-root': {
        padding: 0,
        '.MuiSvgIcon-root': {
          color: PALETTE.primary.main,
          width: '18px'
        }
      },
      paper: {
        marginBottom: 0
      }
    }
  }
};

export const ACCORDION_DETAILS: Components['MuiAccordionDetails'] = {
  styleOverrides: {
    root: {
      padding: '0 0 0 8px',
      marginRight: '4px',
      maxHeight: '300px',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: PALETTE.text.secondary
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.1)',
        backgroundColor: PALETTE.utility.light
      }
    }
  }
};
