import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import { TheLayout, TheContent } from '../containers';

import { routeKeys } from '../shared/utils/constants';
import { removeBackSlash } from '../shared/utils/helpers';

const AboutUs = lazy(() => import('../modules/AboutUs/AboutUs'));
const AiTools = lazy(() => import('../modules/AiTools/AiTools'));
const Home = lazy(() => import('../modules/Home/Home'));
const Parents = lazy(() => import('../modules/Parents/Parents'));
const Administrators = lazy(() => import('../modules/Administrators/Administrators'));
const Students = lazy(() => import('../modules/Students/Students'));
const Teachers = lazy(() => import('../modules/Teachers/Teachers'));
const TermsAndConditions = lazy(() => import('../modules/TermsAndConditions/TermsAndConditions'));
// const Page403 = lazy(() => import('../../modules/auth/Page403'));
// const Page404 = lazy(() => import('../../modules/auth/Page404'));
// const Page500 = lazy(() => import('../../modules/auth/Page500'));

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <TheLayout />,
      children: [
        {
          path: '',
          element: <TheContent />,
          children: [
            { path: removeBackSlash(routeKeys.aboutUs), element: <AboutUs /> },
            { path: removeBackSlash(routeKeys.aiTools), element: <AiTools /> },
            { path: '', element: <Home /> },
            { path: removeBackSlash(routeKeys.parents), element: <Parents /> },
            { path: removeBackSlash(routeKeys.admins), element: <Administrators /> },
            { path: removeBackSlash(routeKeys.students), element: <Students /> },
            { path: removeBackSlash(routeKeys.teachers), element: <Teachers /> },
            { path: removeBackSlash(routeKeys.termsConditions), element: <TermsAndConditions /> },
          ],
        },
      ],
    },
  ]);
}
