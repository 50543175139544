import { Components } from '@mui/material';
import { PRIMARY_FONT_BOLD, PRIMARY_FONT_REG } from '../typography';
import { PALETTE } from '../palette';

export const FORM_HELPER_TEXT: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      fontFamily: PRIMARY_FONT_REG,
      fontSize: '11px',
      padding: 0,
      margin: 0,
      position: 'absolute',
      bottom: '-5px'
    }
  }
};

export const INPUT_LABEL: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      fontFamily: PRIMARY_FONT_BOLD,
      fontSize: '14px',
      color: PALETTE.text.primary,
      marginBottom: '6px'
      //transform: 'translate(14px, 11px) scale(1)'
    },
    asterisk: {
      color: PALETTE.error.main
    }
  }
};

export const FORM_CONTROL_LABEL: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    label: {
      fontSize: '14px'
    }
  }
};
