import { Components } from '@mui/material';
import { PALETTE } from '../palette';
import { pxToRem } from '../../../shared/utils/helpers';

export const CHIP: Components['MuiChip'] = {
  styleOverrides: {
    root: {
      fontSize: pxToRem(10),
      color: PALETTE.primary.contrastText,
      backgroundColor: PALETTE.primary.light,
      height: '26px'
    },

    deleteIcon: {
      color: PALETTE.primary.contrastText,
      margin: '0 2px 0 -6px',
      '&:hover': {
        color: PALETTE.primary.contrastText
      }
    },

    outlined: {
      fontSize: pxToRem(10),
      color: PALETTE.primary.light,
      backgroundColor: PALETTE.common.white,
      height: '26px',
      borderColor: PALETTE.primary.light,
      '.MuiChip-deleteIcon': {
        color: PALETTE.primary.light,
        margin: '0 2px 0 -6px',
        '&:hover': {
          color: PALETTE.primary.light
        }
      }
    }
  }
};
