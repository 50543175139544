import { Grid2 as Grid, Stack, styled, Typography } from '@mui/material';
import { theme } from '../../app/themes/theme';
import { IconTextGridType } from '../utils/types';

const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  background: theme.palette.primary.main,
  marginBottom: '40px',
  width: '160px',
  height: '160px',
  borderRadius: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

const IconTextGrid = ({ items, spacing = 2 }: { items: IconTextGridType; spacing: number }) => {
  return (
    <>
      <Grid container spacing={spacing} sx={{ justifyContent: 'space-between', padding:'60px' }}>
        {items.map((item, index) => (
          <Grid size={spacing === 2 ? 6 : 4} key={index}>
            <Stack key={index} padding="40px" alignItems="center"  sx={{ flex: 1 }}>
              <IconContainer >
                <img src={item.icon} style={{ maxWidth: '90px', maxHeight: '90px', alignSelf: 'center' }} />
              </IconContainer>
              <Typography variant="h2" gutterBottom>
                {item.title}
              </Typography>
              <Typography variant='subtitle1' color="secondary" textAlign='center'>{item.text}</Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default IconTextGrid;
